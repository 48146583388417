import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { arrayOf, normalize } from 'normalizr';
import { SkillSchema } from '../../shared/schemas';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { assignSchemaEntity } from '../../shared/assign';

@Injectable()
export class SkillApi {
  private endpoint = 'skills/';

  constructor(private gateway: ApiGateway) {}

  load(): Observable<any> {
    return this.gateway.get(this.endpoint).pipe(
      map((res) => reformatListResponse('Skill', res)),
      map((data) => normalize(data, arrayOf(SkillSchema), { assignEntity: assignSchemaEntity }))
    );
  }

  add(skillData): Observable<any> {
    return this.gateway.post(this.endpoint, skillData).pipe(
      map((res) => reformatEntityResponse('Skill', res)),
      map((data) => normalize(data, SkillSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  update(id, data): Observable<any> {
    return this.gateway.put(this.endpoint + id, data).pipe(
      map((res) => reformatEntityResponse('Skill', res)),
      map((data) => normalize(data, SkillSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  fetch(skillId): Observable<any> {
    return this.gateway.get(this.endpoint + '/' + skillId).pipe(
      map((res) => reformatEntityResponse('Skill', res)),
      map((data) => normalize(data, SkillSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  delete(id): Observable<any> {
    return this.gateway.delete(this.endpoint + id);
  }
}
