import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[Skill]';

export const SkillActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  DELETE: `${ACTION_PREFIX} Delete`,
  DELETE_SUCCESS: `${ACTION_PREFIX} Delete Success`,
  DELETE_FAILED: `${ACTION_PREFIX} Delete Failed`,
};

export class SkillAction {
  static load(): Action {
    return {
      type: SkillActionType.LOAD,
    };
  }

  static loadSuccess(Skill): Action {
    return {
      type: SkillActionType.LOAD_SUCCESS,
      payload: Skill,
    };
  }

  static loadFailed(err): Action {
    return {
      type: SkillActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static add(SkillData): Action {
    return {
      type: SkillActionType.ADD,
      payload: SkillData,
    };
  }

  static addSuccess(SkillResponse): Action {
    return {
      type: SkillActionType.ADD_SUCCESS,
      payload: SkillResponse,
    };
  }

  static addFailed(err): Action {
    return {
      type: SkillActionType.ADD_FAILED,
      payload: err,
    };
  }

  static update(SkillData): Action {
    return {
      type: SkillActionType.UPDATE,
      payload: SkillData,
    };
  }

  static updateSuccess(SkillResponse): Action {
    return {
      type: SkillActionType.UPDATE_SUCCESS,
      payload: SkillResponse,
    };
  }

  static updateFailed(err): Action {
    return {
      type: SkillActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  static fetch(id: any) {
    return {
      type: SkillActionType.FETCH,
      payload: id,
    };
  }

  static fetchSuccess(response) {
    return {
      type: SkillActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  static fetchFailed(id: string, err) {
    return {
      type: SkillActionType.FETCH_SUCCESS,
      payload: {
        id,
        err,
      },
    };
  }

  static delete(id: any) {
    return {
      type: SkillActionType.DELETE,
      payload: id,
    };
  }

  static deleteSuccess(response) {
    return {
      type: SkillActionType.DELETE_SUCCESS,
      payload: response,
    };
  }

  static deleteFailed(id: string, err) {
    return {
      type: SkillActionType.DELETE_FAILED,
      payload: {
        id,
        err,
      },
    };
  }
}
