import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import groupBy from 'lodash-es/groupBy';
import sortBy from 'lodash-es/sortBy';
import { createSelector } from 'reselect';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import u from 'updeep';

import { getPermissionState } from '../../auth/permission.helper';
import { AppState } from '../../index';
import { filterDeleted, mapAndSortEntities, mapEntity } from '../../shared/entity.helper';
import { getSkillGroups } from '../skill-group/skill-group.service';
import { TreeviewItem } from './../../../shared/ngx-treeview/treeview-item';
import { hasPermissionForAllDepartments } from './../../auth/permission.helper';
import { getSelectedDepartmentIds } from './../../selected-departments/selected-departments.service';
import { getTreeChildren } from './../location/location.service';
import { SkillAction } from './skill.action';
import { SkillApi } from './skill.api';
import { SkillModel, SkillState } from './skill.model';

@Injectable()
export class SkillService {
  constructor(
    private store: Store<AppState>,
    private api: SkillApi,
  ) {}

  load() {
    this.store.dispatch(SkillAction.load());

    return this.api.load().pipe(
      map((response) => {
        this.store.dispatch(SkillAction.loadSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(SkillAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  add(SkillData): Observable<any> {
    this.store.dispatch(SkillAction.add(SkillData));

    return this.api.add(SkillData).pipe(
      map((response) => {
        this.store.dispatch(SkillAction.addSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(SkillAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  update(id, SkillData) {
    this.store.dispatch(SkillAction.update(SkillData));

    return this.api.update(id, SkillData).pipe(
      map((response) => {
        this.store.dispatch(SkillAction.updateSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(SkillAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  fetch(id) {
    this.store.dispatch(SkillAction.fetch(id));

    return this.api.fetch(id).pipe(
      map((response) => {
        this.store.dispatch(SkillAction.fetchSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(SkillAction.fetchFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  save(SkillData) {
    if (SkillData.id) {
      return this.update(SkillData.id, SkillData);
    }

    SkillData = u.omit('id', SkillData);

    return this.add(SkillData);
  }

  delete(id: string) {
    this.store.dispatch(SkillAction.delete(id));

    return this.api.delete(id).pipe(
      map((response) => {
        this.store.dispatch(SkillAction.deleteSuccess(id));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(SkillAction.deleteFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }
}

export const getSkillState = (appState: AppState): SkillState => appState.orm.skills;

export const getSkillIds = compose((state) => state.items, getSkillState);

export const sortSkills = (skill: SkillModel[]): SkillModel[] => sortBy(skill, ['name']);
export const mapAndSortSkills = mapAndSortEntities(sortSkills);

export const getSkillEntities = createSelector(getSkillState, (state) => state.itemsById);
export const getSkills = createSelector(getSkillIds, getSkillEntities, mapAndSortSkills);

export const getSkill = (id: string) => createSelector(getSkillEntities, (entities) => mapEntity(id, entities));

export const getSkillsGroupedBySkillGroup = createSelector(getSkills, (skills) =>
  groupBy(filterDeleted(skills), 'skill_group_id'),
);

export const getSkillsMultiSelect = createSelector(
  getSkillGroups,
  getSkillsGroupedBySkillGroup,
  (skillGroups, skills) =>
    skillGroups
      .filter((skillGroup) => {
        const skillList = skills[skillGroup.id];
        return skills && skillList && skillList.length > 0;
      })
      .map((skillGroup) => {
        const skillGroupTreeviewItem = {
          text: skillGroup.name,
          value: skillGroup.id,
          collapsed: false,
          checked: false,
          children: getTreeChildren(skills[skillGroup.id], 'name', 'id'),
        };

        return new TreeviewItem(skillGroupTreeviewItem);
      }),
);

export const canViewSkills = createSelector(
  getSelectedDepartmentIds,
  getPermissionState,
  (selectedDepartmentIds, permissionState) =>
    hasPermissionForAllDepartments(
      {
        permissions: ['Create roster', 'Edit roster', 'Edit users'],
        departments: selectedDepartmentIds,
      },
      permissionState,
    ),
);
